import React from 'react';
import '../../assets/css/trattamentodati.css'; // Importa il file CSS per gli stili

const TrattamentoDati = () => {
  return (
    <div className="trattamento-dati">
      <h1 className="title">Trattamento dei Dati Personali</h1>
      
      <section>
        <h2 className="subtitle">Introduzione</h2>
        <p className="content">
          La tua privacy è importante per noi. Questa pagina descrive in dettaglio come raccogliamo, utilizziamo, e proteggiamo i tuoi dati personali.
        </p>
      </section>

      <section>
        <h2 className="subtitle">Raccolta dei Dati</h2>
        <ul className="list">
          <li>Dati di contatto (nome, indirizzo email, numero di telefono)</li>
          <li>Informazioni di accesso (username, password)</li>
          <li>Dati di utilizzo (interazioni con il sito, preferenze)</li>
          <li>Contenuti generati dagli utenti (post, commenti, risposte)</li>
        </ul>
      </section>

      <section>
        <h2 className="subtitle">Uso dei Dati</h2>
        <ul className="list">
          <li>Fornire e migliorare i nostri servizi</li>
          <li>Personalizzare la tua esperienza</li>
          <li>Comunicare con te riguardo a aggiornamenti e promozioni</li>
          <li>Garantire la sicurezza e prevenire frodi</li>
        </ul>
      </section>

      <section>
        <h2 className="subtitle">Protezione dei Dati</h2>
        <p className="content">
          Adottiamo misure di sicurezza appropriate per proteggere i tuoi dati personali da accessi non autorizzati, alterazioni, divulgazioni o distruzioni. Queste misure includono:
        </p>
        <ul className="list">
          <li>Uso di protocolli di crittografia</li>
          <li>Accesso limitato ai dati solo al personale autorizzato</li>
          <li>Audit e monitoraggio regolari delle nostre pratiche di sicurezza</li>
        </ul>
      </section>

      <section>
        <h2 className="subtitle">Condivisione dei Dati</h2>
        <p className="content">
          Non vendiamo, affittiamo o condividiamo i tuoi dati personali con terze parti senza il tuo consenso, eccetto nei seguenti casi:
        </p>
        <ul className="list">
          <li>Per adempiere a obblighi legali</li>
          <li>Per proteggere i diritti, la proprietà o la sicurezza della nostra azienda e dei nostri utenti</li>
          <li>Con fornitori di servizi terzi che lavorano per nostro conto</li>
        </ul>
      </section>

      <section>
        <h2 className="subtitle">I Tuoi Diritti</h2>
        <p className="content">
          Hai il diritto di accedere, correggere, cancellare i tuoi dati personali e opporsi al loro trattamento. Per esercitare questi diritti, contattaci all'indirizzo email fornito nella sezione contatti.
        </p>
      </section>

      <section>
        <h2 className="subtitle">Modifiche alla Politica</h2>
        <p className="content">
          Potremmo aggiornare questa politica di trattamento dei dati di tanto in tanto. Ti informeremo di eventuali modifiche significative pubblicando l'aggiornamento sul nostro sito e, se necessario, richiedendo il tuo consenso.
        </p>
      </section>

      <section>
        <h2 className="subtitle">Contatti</h2>
        <p className="content">
          Per qualsiasi domanda o preoccupazione riguardante il trattamento dei tuoi dati personali, puoi contattarci all'indirizzo email: <a href="mailto:privacy@anonymouss.me">privacy@anonymouss.com</a>.
        </p>
      </section>
    </div>
  );
};

export default TrattamentoDati;
