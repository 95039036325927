import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import Home from './pages/Home'; // Importa il componente Home
import MessagesLayout from './components/functional/message';
import Parse from 'parse';
import ChiSiamo from './pages/ChiSiamo';
import TerminiEcondizioni from './pages/TerminiEcondizioni';
import Contattaci from './pages/Contattaci';

Parse.initialize("VLKBnzLuFHycDSBH1me0xnfqBuqIy3HOgbBZrtPH", "WhkPuzkh26x2uzbBX5NcbRNHx0A9IRx1q8q3U86o");
Parse.serverURL = 'https://parseapi.back4app.com';

function UserMessage() {
  const { username } = useParams();
  const [userExists, setUserExists] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const query = new Parse.Query('CustomUser');
      query.equalTo('username', username);
      const user = await query.first();
      setUserExists(!!user);
    };

    fetchUser();
  }, [username]);

  if (userExists === null) {
    return <div>Loading...</div>; // Mostra un caricamento mentre si verifica l'utente
  }

  if (!userExists) {
    return <Navigate to="/home" />;
  }

  return <MessagesLayout />;
}

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/cose" element={<ChiSiamo />} />
          <Route path="/trattamentodati" element={<TerminiEcondizioni />} />
          <Route path="/contattaci" element={<Contattaci />} />
          <Route path="/:username" element={<UserMessage />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;


