import React from 'react';
import '../../assets/css/aboutsocial.css'; // Importa il file CSS per gli stili

const AboutSocial = () => {
  return (
    <div className="about-social">
      <h1 className="title">Benvenuti al Nuovo Social di Q&A Anonimi</h1>
      <p className="description">
        Il nostro nuovo social di Q&A anonimi è progettato per offrire una piattaforma sicura e discreta dove gli utenti possono porre domande e ottenere risposte sincere senza rivelare la propria identità.
      </p>
      <h2 className="subtitle">Come Funziona?</h2>
      <ol className="steps">
        <li>Iscriviti in modo anonimo senza dover fornire informazioni personali.</li>
        <li>Poni domande su qualsiasi argomento e ricevi risposte da altri utenti in modo anonimo.</li>
        <li>Partecipi alle discussioni rispondendo alle domande degli altri in modo onesto e senza pregiudizi.</li>
        <li>Vota le risposte che ritieni più utili e pertinenti per aiutare la community.</li>
      </ol>
      <h2 className="subtitle">Perché è Innovativo?</h2>
      <ul className="features">
        <li><strong>Anonimato Garantito:</strong> Nessuna traccia delle tue informazioni personali viene conservata.</li>
        <li><strong>Comunità Sincera:</strong> Le risposte sono oneste e non influenzate da pregiudizi sociali.</li>
        <li><strong>Facilità d'Uso:</strong> Interfaccia intuitiva e user-friendly per un'esperienza senza stress.</li>
        <li><strong>Sicurezza:</strong> La piattaforma è progettata con misure di sicurezza avanzate per proteggere i tuoi dati.</li>
        <li><strong>Supporto 24/7:</strong> Il nostro team di supporto è disponibile 24 ore su 24 per assisterti in qualsiasi momento.</li>
        <li><strong>Feedback Costante:</strong> Possibilità di inviare feedback e suggerimenti per migliorare continuamente la piattaforma.</li>
      </ul>
      <h2 className="subtitle">Testimonianze</h2>
      <div className="testimonials">
        <div className="testimonial">
          <p>"Finalmente posso fare domande su argomenti delicati senza preoccuparmi di essere giudicato. Questa piattaforma è una benedizione!" - Utente Anonimo</p>
        </div>
        <div className="testimonial">
          <p>"Le risposte sono sempre oneste e utili. È fantastico vedere una comunità così sincera!" - Utente Anonimo</p>
        </div>
      </div>
      
    </div>
  );
};

export default AboutSocial;

