import React from 'react';
import '../../assets/css/appillustration.css'; // Importa il file CSS per gli stili
import homePage from '../../assets/img/homePage.png'
import questionPage from '../../assets/img/QuestionSection.png'
import questions from '../../assets/img/Questions.png'

const AppIllustration = () => {
  return (
    <div className="app-illustration">
      <h2 className="title">Scopri la Nostra App</h2>
      <div className="image-gallery">
        <div className="image-container">
          <img src={homePage} alt="App Screenshot 1" className="app-image" />
          <p className="caption">Interfaccia intuitiva e facile da usare</p>
        </div>
        <div className="image-container">
          <img src={questionPage} alt="App Screenshot 2" className="app-image" />
          <p className="caption">Risposte anonime e sicure</p>
        </div>
        <div className="image-container">
          <img src={questions} alt="App Screenshot 3" className="app-image" />
          <p className="caption">Discussioni coinvolgenti</p>
        </div>
      </div>
      <h1 style={{textAlign: 'center'}}>Cosa Aspetti? Unisciti al divertimento</h1>
      <a href="https://anonymussss.web.app/" className="download-button">Scaricala Subito!</a>
    </div>
  );
};

export default AppIllustration;


