import React from "react";
import Navbar from "../components/functional/Navbar";
import ChiSiamoLayout from "../components/functional/ChiSiamoLayut";
import Footer from "../components/functional/Footer";

function ChiSiamo () {
    return (
        <div>
            <Navbar />
            <ChiSiamoLayout />
            <Footer />
        </div>
    );
}

export default ChiSiamo;