import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ref, set, push, get, child } from "firebase/database";
import { storage, database } from '../firebase';
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import Parse from 'parse';
import axios from 'axios';

const useMessagesLogic = () => {
  const { username } = useParams();
  const [message, setMessage] = useState('');
  const [mode, setMode] = useState('text');
  const [file, setFile] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [aiImageURL, setAiImageURL] = useState('');
  const [apiKey, setApiKey] = useState('');

  useEffect(() => {
    fetch('/keys.json')
      .then(response => response.json())
      .then(data => setApiKey(data.openaiApiKey))
      .catch(error => console.error('Errore durante il caricamento della chiave API:', error));
  }, []);

  const fetchUIDFromParse = async (username) => {
    const query = new Parse.Query('CustomUser');
    query.equalTo('username', username);
    try {
      const results = await query.find();
      if (results.length > 0) {
        const firebaseUid = results.find(result => result.get('firebaseUid')).get('firebaseUid');
        return firebaseUid || null;
      }
      console.log('Utente non trovato');
      return null;
    } catch (error) {
      console.error('Errore durante la ricerca dell\'UID:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchProfileImageUrl = async () => {
      const uid = await fetchUIDFromParse(username);
      if (uid) {
        try {
          const snapshot = await get(child(ref(database), `users/${uid}/profileImageUrl`));
          if (snapshot.exists()) {
            setProfileImageUrl(snapshot.val());
          } else {
            console.log('Nessun URL dell\'immagine del profilo trovato');
          }
        } catch (error) {
          console.error('Errore durante il recupero dell\'URL dell\'immagine del profilo:', error);
        }
      }
    };
    fetchProfileImageUrl();
  }, [username]);

  useEffect(() => {
    document.title = `@${username}`;
  }, [username]);

  const handleNextMode = () => {
    const modes = ['text', 'image', 'video', 'audio', 'ai-image'];
    setMode(modes[(modes.indexOf(mode) + 1) % modes.length]);
    setFile(null);
  };

  const handlePrevMode = () => {
    const modes = ['text', 'image', 'video', 'audio', 'ai-image'];
    setMode(modes[(modes.indexOf(mode) - 1 + modes.length) % modes.length]);
    setFile(null);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const generateAIImage = async (prompt) => {
    if (!apiKey) {
      console.error('Chiave API non disponibile');
      return;
    }

    try {
      const response = await axios.post('https://api.openai.com/v1/images/generations', {
        model: 'dall-e-2',
        prompt: prompt,
        n: 1,
        size: '512x512',
        response_format: 'url'
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        }
      });

      if (response.status === 200) {
        setAiImageURL(response.data.data[0].url);
        return response.data.data[0].url;
      } else {
        console.error('Errore durante la generazione dell\'immagine:', response.data);
      }
    } catch (error) {
      console.error('Errore durante la generazione dell\'immagine:', error.response ? error.response.data : error.message);
    }
  };

  const generateUniqueFileName = () => {
    const randomId = Math.floor(Math.random() * 1e10).toString().padStart(10, '0');
    return `ai-generated-image-${randomId}.png`;
  };

  const handleSubmit = async () => {
    const browserData = navigator.userAgent;
    const ipAddress = await fetch('https://api.ipify.org?format=json')
      .then(res => res.json())
      .then(data => data.ip);
    const browserLanguage = navigator.language || navigator.userLanguage;
    const contentRef = ref(database, 'contentData');
    const newContentRef = push(contentRef);

    let contentData = {
      username: username,
      date: new Date().toISOString(),
      type: mode,
      message: message,
      browserData: browserData,
      ipAddress: ipAddress,
      browserLanguage: browserLanguage,
    };

    if (mode !== 'text' && mode !== 'ai-image' && file) {
      const fileStorageRef = storageRef(storage, `uploads/${file.name}`);
      await uploadBytes(fileStorageRef, file);
      const fileURL = await getDownloadURL(fileStorageRef);
      contentData = { ...contentData, fileURL: fileURL, fileName: file.name };
    }

    if (mode === 'ai-image' && aiImageURL) {
      try {
        const proxyURL = `http://localhost:5000/proxy?url=${encodeURIComponent(aiImageURL)}`;
        const response = await fetch(proxyURL);
        if (!response.ok) {
          throw new Error('Errore durante il recupero dell\'immagine dal proxy');
        }
        const blob = await response.blob();
        const fileName = generateUniqueFileName();
        const fileStorageRef = storageRef(storage, `uploads/${fileName}`);
        await uploadBytes(fileStorageRef, new File([blob], fileName, { type: 'image/png' }));
        const downloadURL = await getDownloadURL(fileStorageRef);
        contentData = { ...contentData, fileURL: downloadURL, fileName: fileName };
      } catch (error) {
        console.error('Errore durante il caricamento dell\'immagine AI su Firebase:', error);
      }
    }

    try {
      await set(newContentRef, contentData);
      alert('Messaggio inviato con successo!');
      setMessage('');
      setFile(null);
    } catch (error) {
      console.error('Errore durante il salvataggio del messaggio:', error);
      alert('Errore durante l\'invio del messaggio. Riprova.');
    }
  };

  return {
    username,
    message,
    setMessage,
    mode,
    setMode,
    handleNextMode,
    handlePrevMode,
    handleFileChange,
    handleSubmit,
    file,
    profileImageUrl,
    generateAIImage,
    aiImageURL,
  };
};

export default useMessagesLogic;














