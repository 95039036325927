import React from "react";
import Navbar from "../components/functional/Navbar";
import Footer from "../components/functional/Footer";
import TrattamentoDati from "../components/functional/TerminiECondizioniLayout";

function TerminiEcondizioni() {
    return (
        <div>
            <Navbar />
            <TrattamentoDati />
            <Footer />
        </div>
    );
}

export default TerminiEcondizioni;