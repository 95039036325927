import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa'; // Importa le icone
import '../../assets/css/footer.css'; // Importa il file CSS per gli stili

const Footer = () => {
  return (
    <footer className="footer">
      <p>© 2025 Anonymous e NGL Cila, tutti i diritti riservati.</p>
      <div className="social-icons">
        <a href="https://facebook.com" className="social-icon">
          <FaFacebook />
        </a>
        <a href="https://twitter.com" className="social-icon">
          <FaTwitter />
        </a>
        <a href="https://www.instagram.com/anonymouss_social?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" className="social-icon">
          <FaInstagram />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
