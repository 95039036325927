import React from 'react';
import '../../assets/css/chisiamo.css'; // Importa il file CSS per gli stili

const ChiSiamoLayout = () => {
  return (
    <div className="chi-siamo">
      <h1 className="title">Anonymous: Una Nuova Frontiera per le Domande & Risposte</h1>
      <p className="description">Chi è il tuo migliore amico? È un segreto!</p>

      <h2 className="subtitle">Chi siamo?</h2>
      <p className="content">
        Siamo designer e sviluppatori del gruppo NGL Cila. Come un gruppo di amici appassionati di tecnologia, abbiamo creato Anonymous nella speranza di offrire un social media che consenta agli utenti di approfondire le loro connessioni con gli amici reali in un ambiente online.
      </p>

      <h2 className="subtitle">Il nostro obiettivo</h2>
      <p className="content">
        Crediamo che le amicizie online siano solo un'estensione del mondo reale e non un sostituto. Le tue interazioni online con gli amici dovrebbero approfondire le tue connessioni piuttosto che danneggiarle. Anonymous ti permette di conoscere i tuoi amici autenticamente attraverso emozionanti e intriganti giochi di Q&A, rafforzando le tue amicizie e aiutandoti a essere il tuo vero io.
      </p>
      <p className="content">
        È difficile fare veri amici ed essere autentici online oggi. Anonymous è qui per aiutarti a fare entrambe le cose in un modo divertente e sicuro.
      </p>

      <h2 className="subtitle">La nostra community</h2>
      <p className="content">
        Quando abbiamo ideato Anonymous, sapevamo che era qualcosa di speciale. Ma non avevamo idea di quanto sarebbe cresciuto. O quanto velocemente.
      </p>
      <p className="content">
        Nel 2024, Anonymous era un concetto discusso nelle chat di gruppo. Oggi è un'app con migliaia di utenti giornalieri in tutto il mondo, aiutando i nostri utenti a connettersi tra loro restando al sicuro online. È chiaro che Anonymous ha colpito nel segno, riempiendo un grande vuoto nello spazio dei social media.
      </p>
      <p className="content">
        Questo è successo grazie alla nostra community di Anonymous: voi! Rendete l'app fantastica. Rimanete autentici. Siamo felici che siate qui. E non potremmo farlo senza di voi.
      </p>
      <p className="content">
        I valori fondamentali della community di Anonymous sono autenticità, gentilezza e connessione. Assicurati di leggere le nostre Linee Guida della Community per maggiori informazioni su perché esortiamo i nostri utenti ad essere gentili, essere reali e essere se stessi.
      </p>

      <h2 className="subtitle">Contattaci</h2>
      <p className="content">Ami l'app Anonymous? Vogliamo saperlo! Contattaci all'indirizzo hello@anonymouss.me. ❤️</p>
    </div>
  );
};

export default ChiSiamoLayout;
