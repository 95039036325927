import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaPaperPlane, FaSpinner } from 'react-icons/fa'; // Importa l'icona dell'aereo e del caricamento
import useMessagesLogic from './messageLogic';
import styles from '../../assets/css/App.module.css';
import spinners from '../../assets/img/icons8-spinner.gif'

const MessagesLayout = () => {
  const {
    username,
    message,
    setMessage,
    mode,
    setMode,
    handleFileChange,
    handleSubmit,
    file,
    profileImageUrl,
    generateAIImage, // Aggiunto generateAIImage
    aiImageURL, // Aggiunto aiImageURL
  } = useMessagesLogic();

  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Stato per gestire il caricamento

  const modes = ['text', 'image', 'video', 'audio', 'ai-image'];

  const handleNextMode = () => {
    const currentIndex = modes.indexOf(mode);
    const nextIndex = (currentIndex + 1) % modes.length;
    setMode(modes[nextIndex]);
  };

  const handlePrevMode = () => {
    const currentIndex = modes.indexOf(mode);
    const prevIndex = (currentIndex - 1 + modes.length) % modes.length;
    setMode(modes[prevIndex]);
  };

  const renderFilePreview = () => {
    if (!file) return null;
    const fileURL = URL.createObjectURL(file);
    if (mode === 'image') {
      return <img src={fileURL} alt="Preview" className={styles.preview} />;
    } else if (mode === 'video') {
      return <video controls className={styles.preview}><source src={fileURL} type={file.type} /></video>;
    } else if (mode === 'audio') {
      return <audio controls className={styles.preview}><source src={fileURL} type={file.type} /></audio>;
    }
  };

  const handleGenerateAIImage = async () => {
    setIsLoading(true); // Imposta lo stato di caricamento a vero
    await generateAIImage(prompt);
    setIsLoading(false); // Imposta lo stato di caricamento a falso
  };

  return (
    <div className={styles.container}>
      <div className={styles['message-box']}>
        <div className={styles['message-header']}>
          <div className={styles['profile-pic']} alt="Immagine del profilo">
            {profileImageUrl && <img src={profileImageUrl} alt="Profile" style={{ width: '60px', height: '60px', borderRadius: '50%', objectFit: 'cover' }} />}
          </div>
          <div>
            <div className={styles.username}>@{username}</div>
            <div className={styles['prompt-text']}><b>Mandami messaggi anonimi!</b></div>
          </div>
        </div>
        {mode === 'text' && (
          <input
            style={{
              fontSize: '25px',
              color: 'black',
              textAlign: 'center',
              marginTop: '20px',
              marginBottom: '20px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '90%',
              maxWidth: '500px',
              padding: '10px',
              fontWeight: 'bold'
            }}
            type="text"
            className={styles['message-input']}
            placeholder="mandami messaggi anonimi..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        )}
        {mode === 'image' && (
          <div className={styles['file-upload']}>
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleFileChange}
              className={styles['custom-file-input']}
              id="file-upload"
            />
            <label htmlFor="file-upload" className={styles['custom-file-label']}>
              {file ? file.name : 'Invia una tua foto'}
            </label>
            {renderFilePreview()}
          </div>
        )}
        {mode === 'video' && (
          <div className={styles['file-upload']}>
            <input
              type="file"
              accept="video/mp4"
              onChange={handleFileChange}
              className={styles['custom-file-input']}
              id="file-upload"
            />
            <label htmlFor="file-upload" className={styles['custom-file-label']}>
              {file ? file.name : 'Invia un video'}
            </label>
            {renderFilePreview()}
          </div>
        )}
        {mode === 'audio' && (
          <div className={styles['file-upload']}>
            <input
              type="file"
              accept="audio/ogg, audio/mp4"
              onChange={handleFileChange}
              className={styles['custom-file-input']}
              id="file-upload"
            />
            <label htmlFor="file-upload" className={styles['custom-file-label']}>
              {file ? file.name : 'Invia un audio'}
            </label>
            {renderFilePreview()}
          </div>
        )}
        {mode === 'ai-image' && (
          <div className={styles['aiinput-prompt-container']}>
            <label htmlFor="ai-prompt" className={styles['aiinput-prompt-label']}>
              Scrivi un prompt per generare un'immagine con l'IA:
            </label>
            <div className={styles['aiinput-input-container']}>
              <input
                type="text"
                id="ai-prompt"
                className={styles['aiinput-prompt-input']}
                placeholder="Descrivi l'immagine che desideri..."
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
              <button
                onClick={handleGenerateAIImage}
                className={styles['aiinput-icon-button']}
              >
                <FaPaperPlane color="white" /> {/* Icona dell'aereo in bianco */}
              </button>
            </div>
            {isLoading ? (
              <img src={spinners} alt="Spinner"  />
            ) : (
              aiImageURL && (
                <div>
                  <div className={styles['aiinput-image-placeholder']}>
                    <img src={aiImageURL} alt="Generated" style={{ width: '100%', borderRadius: '10px' }} />
                  </div>
                  <p>Per poter inviare l'immagine generata segui questi passaggi:
                  <br />
                    1) Salva L'immagine generata in Foto (IOS), o scaricala (Android)
                    <br />
                    2) Torna indietro e seleziona la schermata di invio di un immagine
                    <br />
                    3) Seleziona l'immagine generata
                    <br />
                    4) Goditi il divertimento inviandola al destinatario :)
                    <br />
                    NOTA: NON USARE IL TASTO INVIA SEGNALE PER INVIO DELL'IMMAGINE, POICHE' PER PROBLEMI TECNICI NON FUNZIONA.
                  </p>
                </div>
              )
            )}
          </div>
        )}
        <div className={styles['mode-selector']}>
          <button onClick={handlePrevMode} className={styles['nav-button']}>
            <FaArrowLeft />
          </button>
          <button onClick={handleNextMode} className={styles['nav-button']}>
            <FaArrowRight />
          </button>
        </div>
        <div className={styles['button-group']}>
          <button className={`${styles['upload-button']} ${styles['shake-animation']}`}>
            {mode === 'text' && 'Scrivi un testo'}
            {mode === 'image' && 'Invia un\'immagine'}
            {mode === 'video' && 'Invia un video'}
            {mode === 'audio' && 'Invia un audio'}
            {mode === 'ai-image' && 'Invia un\'immagine generata dall\'IA'}
          </button>
        </div>
      </div>
      <button
        style={{
          backgroundColor: 'black',
          color: 'white',
          borderRadius: '30px',
          padding: '20px 40px',
          border: 'none',
          cursor: 'pointer',
          marginTop: '10px',
          fontSize: '28px'
        }}
        onClick={handleSubmit}
      >
        Invia segnale!
      </button>
      <div className={styles.footer}> <p><i className="fas fa-lock"></i> Anonymous Messages. Copyright © 2024, NGL Cila and Cila Studios</p> <p><i className="fas fa-thumbs-down"></i> 223 persone hanno appena cliccato il pulsante</p> <a href="https://anonymouss.me" className={styles.button}>Ottieni i tuoi messaggi!</a> <p className={styles.privacy}>Termini Privacy</p> 
      </div> 
      </div>
      ); 
    }; 
    
    export default MessagesLayout;






























