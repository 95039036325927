import React from "react";
import ContattaciLayout from "../components/functional/ContattaciLayout";
import Navbar from "../components/functional/Navbar";
import Footer from "../components/functional/Footer";

function Contattaci () {
    return (
        <div>
            <Navbar />
            <ContattaciLayout />
            <Footer />
        </div>
    );
}

export default Contattaci